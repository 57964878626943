import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import HelpIcon from "@mui/icons-material/Help";
import SearchIcon from "@mui/icons-material/Search";
import Categories from "./categories.jsx";
import CategoryPosts from "./categoryPosts.jsx";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllKnowledgeBasedDataList,
  getAllKnowledgeBasedDataListUsingSearch,
} from "../../../redux/knowledge/knowledgeSearch";
import CategoriesPostSkeleton from "./skeleton/categoriesPost.jsx";
import { debounce } from "lodash";
import SearchResultsArea from "./searchDetail.jsx";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import CategoryDetail from "./categoryDetail.jsx";
function KnowledgeBaseMain(props) {
  const { classes } = props;
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const dispatch = useDispatch();
  const { allKnowledgeBaseData, loading, searchData } = useSelector(
    (state) => state.knowledgeBase.search
  );
  const storedUsers = useSelector((item) => item.users.storedListOfUsers?.list);

  const { recentlyAdded, categories } = allKnowledgeBaseData;
  useEffect(() => {
    if (searchText.length === 0) dispatch(getAllKnowledgeBasedDataList());
  }, [searchText]);
  const debouncedSearch = useRef(
    debounce(async (val) => {
      if (val?.length > 0) {
        dispatch(getAllKnowledgeBasedDataListUsingSearch(val));
      }
    }, 300)
  ).current;

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 0) debouncedSearch(e.target.value);
  };

  return (
    <Box className={classes.pageRoot}>
      <Container>
        <Box className={classes.knowledgeBaseHeader}>
          <Typography className={classes.knowledgeBaseHeading}>
            <HelpIcon sx={{ color: "#757575", mt: "-2px" }} fontSize="large" />
            Knowledge Base
          </Typography>
          <OutlinedInput
            onChange={onChangeSearchText}
            value={searchText}
            type="search"
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            sx={{ bgcolor: "white", width: "360px", background: "#F8F8F8" }}
            size="small"
            placeholder="Search Knowledge Base"
          />
        </Box>

        {searchText?.length > 0 ? (
          <SearchResultsArea
            category={{ posts: searchData }}
            {...props}
            categoryTitle="Filtered results"
            storedUsers={storedUsers}
          />
        ) : (
          <Box>
            {loading ? (
              <CategoriesPostSkeleton />
            ) : (
              <Box>
                {selectedCategory?.id ? (
                  <Box className={classes.categoryDetailSection}>
                    <Button
                      variant="text"
                      color="inherit"
                      className={classes.categoryBackLink}
                      onClick={() => setSelectedCategory(null)}
                    >
                      <KeyboardBackspaceRoundedIcon /> Back to Categories
                    </Button>
                    <CategoryDetail
                      selectedCategory={selectedCategory}
                      storedUsers={storedUsers}
                      {...props}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Categories
                      {...props}
                      setSelectedCategory={setSelectedCategory}
                    />
                    <CategoryPosts
                      category={{
                        posts: recentlyAdded,
                        id: 1,
                        title: "Recently Added",
                      }}
                      {...props}
                      categoryTitle="Recently Added"
                      storedUsers={storedUsers}
                      setSelectedCategory={setSelectedCategory}
                    />
                    {categories?.map((item, index) => (
                      <CategoryPosts
                        storedUsers={storedUsers}
                        category={item}
                        key={index}
                        setSelectedCategory={setSelectedCategory}
                        {...props}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "KnowledgeBaseMainStyle" })(
  KnowledgeBaseMain
);
